import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import he from "he"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

import "../css/blog.css"

function BlogES({  location, data }) {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const pageData = data["allDblog"]["edges"][0]["node"]

  return (
    <>
      <Metatags
        title={`NetBistrot - Digital agency | ${he.decode(pageData.titleEs)}`}
        description={`Blog - ${he.decode(pageData.titleEs)}`}
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords={he.decode(pageData["keywordsEs"])}
        language="es"
      >
        <script type="application/ld+json">
          {`{
        "@context":"https://schema.org/",
        "@type":            "BlogPosting",
        "@id":              "${fullUrl}",
        "mainEntityOfPage": "${fullUrl}",
        "headline":         "${he.decode(pageData.titleEs)}",
        "name":             "${he.decode(pageData.titleEs)}",
        "description":      "${he.decode(pageData.titleEs)}",
        "datePublished": "${
          new Date(pageData["date"]).toISOString().split("T")[0]
        }",
        "dateModified": "${
          new Date(pageData["date"]).toISOString().split("T")[0]
        }",
        "author": {
            "@type": "Organization",
            "name": "NetBistrot",
            "url": "https://netbistrot.com/en/about-us/",
            "image": {
                "@type": "ImageObject",
                "@id": "https://netbistrot.com/netbistrot-icon-540.png",
                "url": "https://netbistrot.com/netbistrot-icon-540.png",
                "height": "540",
                "width": "540"
            }
        },
        "publisher": {
            "@type": "Organization",
            "name": "NetBistrot",
            "url": "https://netbistrot.com/en/about-us/",
            "logo": {
                "@type": "ImageObject",
                "@id": "https://netbistrot.com/netbistrot-icon-540.png",
                "url": "https://netbistrot.com/netbistrot-icon-540.png",
                "height": "540",
                "width": "540"
            }
        },
        "url": "${fullUrl}",
        "isPartOf": {
            "@type" : "Blog",
             "@id": "${siteUrl}/es/blog",
             "name": "NetBistrot Blog",
             "publisher": {
                 "@type": "Organization",
                 "name": "NetBistrot"
             }
         }
    }`}
        </script>
      </Metatags>
      <NavBar
        slugs={{
          en: pageData.slugEn,
          es: pageData.slugEs,
          pt: pageData.slugPt,
        }}
        slugKey="blogs"
      />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>{he.decode(pageData.titleEs)}</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              {pageData["picture"] ? (
                <>
                  <div className="h-10" />
                  <GatsbyImage
                    className="img-responsive"
                    image={getImage(data.file)}
                    backgroundColor={false}
                    alt="pwa"
                  />
                </>
              ) : (
                <></>
              )}
              <div className="h-10" />
              <span
                className="text-lg blog-body"
                dangerouslySetInnerHTML={{ __html: he.decode(pageData.bodyEsPublish) }}
              ></span>

              <div className="h-10" />
              <p
                dangerouslySetInnerHTML={{
                  __html: he.decode(pageData["tagsEs"]),
                }}
              />
              <div className="h-10" />
              <p
                dangerouslySetInnerHTML={{
                  __html: new Date(pageData["date"]).toLocaleDateString(
                    "es-ES"
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="es" />
    </>
  )
}

export default BlogES

export const appSyncQuery = graphql`
  query ($id: String!, $pictureName: String!) {
    allDblog(filter: { id: { eq: $id } }) {
      edges {
        node {
          bodyEsPublish
          date
          published
          titleEs
          slugEn
          slugEs
          slugPt
          keywordsEs
          tagsEs
          picture
        }
      }
    }

    file(name: { eq: $pictureName }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
